.container {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
}

.avatarImg {
  height: 32px;
  width: 32px;
  border-radius: 16px;
}

.bodyDiv {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.botNameText {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.contentDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
}