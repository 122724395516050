.container {
  border-top: 1px solid #E5E5E580;
  padding-top: 20px;
}

.descriptionText {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #595A5A;
}

.suggestionListDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.suggestionDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0 13px 0;
  cursor: pointer;
}

.suggestionDiv:not(:last-child) {
  border-bottom: 1px solid #E5E5E580;
}

.suggestionText {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #1A1918;
  margin: 0 12px 0 0;
  flex: 1;
}

.plusButton {
  width: 20px;
  height: 20px;
}