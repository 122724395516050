.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: linear-gradient(180deg, #F5F5F500 0%, #F5F5F5 17.09%, #F5F5F5 100%);
  padding: 40px 20px 28px 20px;
}

.presetQuestionsPositioner {
  position: relative;
  margin: 0px -20px;
}

.presetQuestionWrapper {
  width: 100%;
  position: absolute;
  bottom: 24px;
  z-index: 2;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  height: 56px;
  margin-bottom: 12px;
  border-radius: 28px;
  background-color: #ffffff;
}

.input {
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0 16px 0 20px;
  outline: none;
}

.input::placeholder {
  color: #9B9B9B;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 12px;
  border: none;
  background-color: #000000;
}

.submitButton:disabled {
  background-color: #ECECEC;
}

.submitIcon {
  width: 16px;
  height: 16px;
}

.noticeText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #9b9b9b;
}