.row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0 20px;
}

.bubble {
  background-color: #ececec;
  max-width: 248px;
  padding: 12px 14px;
  border-radius: 14px 0 14px 14px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin: 0;
}