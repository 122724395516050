.container {
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;             /* Chrome, Safari, Opera */
}

.itemContainer {
  flex-shrink: 0;
  width: 152px;
  height: 94px;
  background-color: #F5F5F5;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  cursor: pointer;
  padding: 12px 16px;
}

.itemSpacer {
  flex-shrink: 0;
  width: 20px - 8px;
}

.titleText {
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #595a5a;
  margin-bottom: 4px;
}

.contentText {
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}