.container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F5F5F5;
    max-width: 768px;
    margin: 0 auto;
    height: var(--inner-height);
}

.header {
    height: 54px;
    margin-bottom: 8px;
    padding: 7px 8px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 4px;
}

.headerButton {
    width: 40px;
    height: 40px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerButtonIcon {
    width: 24px;
    height: 24px;
}

.chatScrollArea {
    flex: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
}

.chatScrollArea::-webkit-scrollbar {
    display: none;             /* Chrome, Safari, Opera */
}

.chatPane {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.bottomBar {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.scrollBottomButtonDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -14px;
}

.scrollBottomButton {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0.8px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    box-shadow: 0 2.6px 5px -2.6px #0000001A;
}

.scrollBottomButtonIcon {
    width: 16px;
    height: 16px;
}