.container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 6px 16px;
  gap:8px
}

.spaceDiv:not(:last-child) {
  border-bottom: 1px solid #e5e5e580;
}

.spaceEmptyDiv {
  height: 216px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.spaceImg {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.summaryDiv {
  flex: 1;
  margin-left: 12px;
}

.spaceNameText {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.spaceSubText {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  color: #000000;

  span:not(:last-child)::after {
    content: "\00B7";
    margin: 0 4px;
    display: inline-block;
    font-weight: 400;
    color: #000000;
  }
}

.spaceSubEmphasisText {
  font-weight: 700;
}

.spaceSubActiveEmphasisText {
  color: #48A7F8;
}

.reasoningText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.buttonGroupDiv {
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 8px 0 20px 0;
}

.buttonIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.buttonText {
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
}

.blackButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  height: 32px;
  border-radius: 16px;
  border: none;
  padding: 0 14px;
  text-decoration: none;
}

.outlineButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  color: #000000;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  padding: 0 14px;
  text-decoration: none;
}